'use client';

import { ProductRow as ProductRowUI } from '@components/Product/ProductRow';
import { ProductRowHeading } from '@components/Product/ProductRowHeading';
import { TrackPromotionImpression } from '@components/TrackPromotionImpression/TrackPromotionImpression';
import { usePromoTrackingEvents } from '@components/TrackPromotionImpression/usePromoTrackingEvents';
import { useEntitlements } from '@contexts/EntitlementsContext';
import { useProductTags } from '@contexts/ProductTagsContext';
import { useRouter } from '@contexts/Router/RouterContext';
import { ProductRowFragment } from '@graphql/generated-contentful/graphql';
import { longTextToProductIds } from '@lib/util/longTextToProductIds';
import { mapToProductCardProps } from './mapToProductCardProps';
import { usePackagesByIds } from './usePackagesByIds';

export type ProductRowProps = {
  data: ProductRowFragment;
  position: number;
};

const ProductRow = ({ data, position }: ProductRowProps) => {
  const { locale } = useRouter();
  const {
    productRowTitle,
    productRowDescription,
    text,
    linkHref,
    linkTitle,
    productIdList,
    titleInternal,
    trackAsPromo,
  } = data;

  const link =
    linkHref && linkTitle ? { href: linkHref, label: linkTitle } : undefined;
  const productIDs = productIdList ? longTextToProductIds(productIdList) : [];
  const { data: products, error, isLoading } = usePackagesByIds(productIDs);

  const productTags = useProductTags();
  const { getUserEntitlement } = useEntitlements();

  const { promoClickTrackingEvent, promoViewTrackingEvent } =
    usePromoTrackingEvents({
      title: titleInternal || '',
      typename: 'ProductRow',
      position,
      trackAsPromo: trackAsPromo ?? false,
      ctas: link?.label ? [link.label] : [],
    });

  if (isLoading || error || !products || products.length === 0) {
    return null;
  }

  return (
    <TrackPromotionImpression
      onImpression={promoViewTrackingEvent}
      shouldTrack={trackAsPromo ?? false}
    >
      <ProductRowHeading
        title={productRowTitle}
        description={productRowDescription}
        text={text}
        link={link}
        onLinkClick={promoClickTrackingEvent}
      />
      <ProductRowUI
        products={mapToProductCardProps(
          products,
          productTags,
          getUserEntitlement
        )}
        locale={locale}
      />
    </TrackPromotionImpression>
  );
};

export default ProductRow;
