import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { ToggleMoreItemsButton } from '@ui-components/components/SubCategorySelector/ToggleMoreItemsButton';
import classNames from 'classnames';
import { useState } from 'react';
import { ProductCard, ProductCardProps } from './ProductCard';

type ProductRowProps = {
  products: ProductCardProps[];
  maxInitialDisplay?: number;
  showMoreItemsLabel?: string;
  showLessItemsLabel?: string;
  locale: string;
};

export const ProductRow = ({
  products = [],
  maxInitialDisplay,
  showMoreItemsLabel = 'Show {{number}} more',
  showLessItemsLabel = 'Show Less',
  locale,
}: ProductRowProps) => {
  const hasMoreItemsThanDisplayMax =
    maxInitialDisplay !== undefined && products.length > maxInitialDisplay;
  const excessItems = hasMoreItemsThanDisplayMax
    ? products.length - maxInitialDisplay
    : 0;

  const [showMoreItems, setShowMoreItems] = useState(false);

  const handleToggleShowMoreItems = () => {
    setShowMoreItems(!showMoreItems);
  };

  return (
    <div className="container relative">
      <div className="hide-scrollbar flex grid-cols-4 gap-4 overflow-x-scroll md:grid md:overflow-visible lg:gap-5">
        {products.map((product, index) => (
          <ProductCard
            key={product.productId}
            className={classNames(
              'mr-0 min-w-[242px] md:min-w-full',
              maxInitialDisplay !== undefined &&
                index >= maxInitialDisplay &&
                !showMoreItems &&
                'md:hidden' // Add "hidden" class to items over the maxInitialDisplay on bigger screens
            )}
            locale={locale}
            {...product}
          />
        ))}
      </div>
      {hasMoreItemsThanDisplayMax && (
        <div
          className={classNames('mt-6 hidden justify-center md:flex')}
          data-test="product-row-toggle-more"
        >
          <ToggleMoreItemsButton
            excessItems={excessItems}
            onClick={handleToggleShowMoreItems}
            label={
              showMoreItems
                ? showLessItemsLabel
                : showMoreItemsLabel.replace(
                    '{{number}}',
                    excessItems.toString()
                  )
            }
            icon={showMoreItems ? CaretUp : CaretDown}
          />
        </div>
      )}
    </div>
  );
};
